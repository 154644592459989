<template>
  <v-container fluid :key="divs">
    <v-row dense>
      <v-col dense cols="12" sm="2" md="2" v-if="components_xtree.length > 0">
        xtree
        <v-row
          align="baseline"
          dense
          class="rowh2"
          no-gutters
          v-for="item in components_xtree"
          :key="item.id"
        >
          <v-select
            v-if="stap == 'A'"
            class="rowh2"
            v-model="item.asfound"
            return-object
            outlined
            :label="item.component"
            @change="valuechanged"
            dense
            :readonly="t_readonly"
            @input="changes++"
            :items="['Open', 'Closed']"
          >
          </v-select>
          <v-select
            v-else
            class="rowh2"
            v-model="item.asleft"
            return-object
            outlined
            :readonly="t_readonly"
            :label="item.component"
            @change="valuechanged"
            dense
            @input="changes++"
            :items="['Open', 'Closed']"
          >
          </v-select>
        </v-row>
      </v-col>
      <v-col dense cols="12" sm="8" md="8" v-if="components_annuli.length > 0">
        Annulus
        <v-row dense align="baseline" no-gutters>
          <v-col
            dense
            cols="12"
            sm="6"
            md="6"
            v-for="item in components_annuli"
            :key="item.id"
          >
            <v-row class="rowh3" dense no-gutters>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-if="stap == 'A'"
                  style="padding-right: 8px"
                  v-model="item.asfound"
                  outlined
                  return-object
                  dense
                  @change="valuechanged"
                  :label="item.component"
                  :items="['Open', 'Closed']"
                  @input="changes++"
                  :readonly="t_readonly"
                >
                </v-select>
                <v-select
                  v-else
                  style="padding-right: 8px"
                  v-model="item.asleft"
                  outlined
                  return-object
                  dense
                  @change="valuechanged"
                  :label="item.component"
                  :items="['Open', 'Closed']"
                  @input="changes++"
                  :readonly="t_readonly"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col dense cols="12" sm="2" md="2" v-if="pressures.length > 0">
        Pressure ({{ pressure_unit }})
        <v-row
          align="baseline"
          class="pb-2"
          dense
          no-gutters
          v-for="item in pressures"
          :key="item.id"
        >
          <v-text-field
            class="rowh2"
            autocomplete="off"
            type="number"
            outlined
            :label="item.component"
            dense
            v-if="stap == 'A'"
            v-model="item.asfound"
            @input="changes++"
            :readonly="t_readonly"
          >
          </v-text-field>
          <v-text-field
            class="rowh2"
            autocomplete="off"
            type="number"
            outlined
            :label="item.component"
            dense
            v-else
            v-model="item.asleft"
            @input="changes++"
            :readonly="t_readonly"
          >
          </v-text-field>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  props: {
    item: Object,
    founds: Array,
    stap: String,
    t_readonly: { type: Boolean, default: true },
  },
  data() {
    return {
      pressure_unit: "",
      divs: 500,
      editedItem: {},
      components_xtree: [],
      components_annuli: [],
      pressures: [],
      annuli: [],
      changes: 0,
    };
  },

  mounted() {
    this.pressure_unit = this.$store.state.options[0].pressure;
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
      } else {
        this.editedItem = Object.assign(
          {},
          { id: -1, well_id: -1, statut_id: 5 }
        );
      }
    }

    this.well_change();
  },
  methods: {
    valuechanged() {
      this.$emit("changes", this.changes);
    },
    well_change() {
      this.components_xtree = [];
      this.components_annuli = [];
      (this.pressures = []), (this.annuli = []);
      if (this.founds.length > 0) {
        this.components_xtree = this.founds.filter(
          (x) => x.cmp_type == "treecap" && x.state == "S"
        );
        this.components_annuli = this.founds.filter(
          (x) => x.cmp_type == "annilus" && x.state == "S"
        );
        this.pressures = this.founds.filter((x) => x.state == "P");
      }
    },
  },
};
</script>
<style>
.rowh2 {
  height: 40px !important;
  margin-bottom: 8px !important;
}
.rowh3 {
  width: 100% !important;
  height: 40px !important;
  margin-bottom: 8px !important;
  margin-right: 8px !important;
}
</style>
